import { createContext, useContext, useEffect, useState } from "react";
import forceRenderViewport from "../helper/forceRenderViewport";

export const sidePanelContext = createContext(null);
const { Provider } = sidePanelContext;

const SidePanelsProvider = ({ children, value: initialValue, servicesManager }) => {
  const [openStates, setOpenStates] = useState(initialValue.openStates);
  const { viewportGridService, cornerstoneViewportService } = servicesManager.services;

  useEffect(() => {
    setTimeout(() => {
      forceRenderViewport(viewportGridService, cornerstoneViewportService);
    }, [800]);
  }, [openStates]);
  return <Provider value={{ openStates, setOpenStates }}>{children}</Provider>;
};

export default SidePanelsProvider;
