import { useStudiesState } from "../DynamicViewer/state";
import { getGender } from "../../utils";
import { useEffect } from "react";
import Store from "../../utils/Store";
import _ from "lodash";
import { useStoreState } from "pullstate";
import { useTranslation } from "react-i18next";

const PatientBio = (props) => {
  const {isSimple} = props
  const { t } = useTranslation();
  const { getState } = useStudiesState();
  const { studies } = getState();

  const age = _.get(studies, ["age"]);
  const gender = getGender(_.get(studies, ["gender"]))?.shortLabel;

  useEffect(() => {
    const _patientMetadata = {
      name: _.get(studies, ["name"]),
      patientID: _.get(studies, ["patient_id"]),
    };

    Store.update((s) => {
      s.patient = _patientMetadata;
    });
  }, [studies]);

  const { currentPatient } = useStoreState(Store, (s) => ({
    currentPatient: s.patientDetail,
  }));

  const _gender = getGender(currentPatient?.gender)?.shortLabel;
  const __gender = _gender ?? gender

  if(isSimple) {
    return (<div>
      <span className="text-left mt-1">{currentPatient?.age ?? age} Y</span>
      {' | '}<span className="text-left mt-1">{__gender || "NA"}</span>
    </div>)
  }
  return (
    <div className="hidden md:flex items-center justify-start p-5 border-b text-gray-200 border-gray-800">
      <div className="mr-5">
        <div className="text-sm text-gray-400 font-bold uppercase">
          {t("gender_label")}
        </div>
        <div className="text-left mt-1">{(_gender ?? gender) || "NA"}</div>
      </div>
      <div className="mr-5">
        <div className="text-sm text-gray-400 font-bold uppercase">{t("age_label")}</div>
        <div className="text-left mt-1">{currentPatient?.age ?? age} Y</div>
      </div>
    </div>
  );
};

export default PatientBio;
