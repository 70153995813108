function forceRenderViewport(viewportGridService, cornerstoneViewportService) {
  const renderingEngine = cornerstoneViewportService.getRenderingEngine();
  const viewports = renderingEngine.getViewports();

  if (viewports.length !== 0) {
    viewports.map((viewport) => {
      viewport.resetCamera();
      viewport.render();
    });
  }
}

export default forceRenderViewport;
