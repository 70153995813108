import React, { createContext, useReducer, useCallback, useContext } from "react";

const DEFAULT_STATE = {
  loading: true,
  error: false,
  studies: [],
};

export const StudiesStateContext = createContext(DEFAULT_STATE);

export function StudiesStateProvider({ children, service }) {
  const studiesStateReducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return { ...state, loading: action.payload };

      case "error":
        return { ...state, loading: false, error: action.payload };
      case "success":
        return {
          ...state,
          loading: false,
          error: false,
          studies: action.payload,
        };
      default:
        return state;
    }
  };

  const [studiesState, dispatch] = useReducer(studiesStateReducer, DEFAULT_STATE);

  const getState = useCallback(() => studiesState, [studiesState]);

  const setState = useCallback(
    (type, payload) =>
      dispatch({
        type,
        payload,
      }),
    [dispatch]
  );

  const api = {
    getState,
    setState,
  };

  return (
    <StudiesStateContext.Provider value={api}>{children}</StudiesStateContext.Provider>
  );
}

export const useStudiesState = () => useContext(StudiesStateContext);

export default StudiesStateProvider;
