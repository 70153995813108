import axios from 'axios'
import servicesUtils from '../../../utils/servicesUtils';

const reqObj = axios.create({
    timeout: 50000,
    withCredentials: true,
})

const getAssist = (data) => {

    const params = {
        ...data
    }
    return servicesUtils.postService("gpt/gpt_response/", params).then(response => response.data)    
};

export default getAssist;
